<template>
<div class="LayoutHolder" id="PageTop">
<section class="WhiteSmoke">
		<div class="Container">
			<div class="Box ProductListHead">
				<div class="HeadingGroup">
					<h1>Kit</h1>
					<div class="TxtEditor">
						<p v-html="risorse('KitDescription')"></p>
					</div>
				</div>
				<!-- <div class="ItemThumbLayout">
					<div class="ItemThumbLayoutSwitch Grid"><button onClick="grid();"><img src="/images/GridViewIcon.png" srcset="/images/GridViewIconRetina.png 2x" alt="Grid view" /></button></div>
					<div class="ItemThumbLayoutSwitch List"><button onClick="list();"><img src="/images/ListViewIcon.png" srcset="/images/ListViewIconRetina.png 2x" alt="Listed view" /></button></div>
				</div> -->
			</div>
		</div>
		<div class="Container">
			<div class="Box">
				<div class="Binder Big">
					<div class="Content">
						<div class="Binder Big">
							<ProductCard
								v-for="Kit in Kits" 
								:key="Kit.CodiceArticolo"
								:Prodotto="Kit"
								:Template="'FeaturedProduct'"
							/>
						</div>
					</div>
				</div>	
			</div>
		</div>
</section>
</div>
</template>

<script>
import ProductsService from "@/services/products.service.js";
import { modelMixin, risorseMixin} from "@/mixin.js";
import ProductCard from "@/components/ProductCard.vue";

export default {
    name: "Kits",
    data() {
        return {
            Kits: [],
        };
    },
    methods: {
        getData: function () {
            let token = this.$store.getters.getToken;
            let shop = this.$store.getters.getShop;
            ProductsService.getArticoli(token, shop, "KIT").then(
                data => {
                    if (!data) {
                        this.$router.push("/");
                    } else {
                        this.Kits = data;
                    }
                }
            );
        }
    },
    mixins: [modelMixin, risorseMixin],
    mounted: function () {
        this.getData();
    },
    components: { ProductCard }
}
</script>
<style scoped>
.Content{ padding-left:0px; width: 100%; border:0px;}
</style>
